import {Link} from "react-router-dom"

function Home({products, setProductClicked}) {

    return (
        <div className="container">
            <main id="HomeMain" className="d-flex justify-content-center align-items-center">
                <div>
                    <h1 className="mb-5">Always <br className="NoPC" /> a Kid</h1>
                    <h2 className="text-center aak-typo mt-0">Le monde par un enfant</h2>
                </div>
            </main>
            <div className="glass p-3 my-5">
                <div className="glass p-3">
                    <h2 className="aak-typo fs-1">Nos valeurs</h2>
                    <h3 className="fs-3">Eco-responsabilité</h3>
                    <p>
                        L'industrie de la mode est l'une des plus polluante sur terre. La production de matière première représente partie de ce problème. <br/>
                        C'est pourquoi nous avons choisi de confectionner nos produits qu'à partir de matériaux bio ou recyclés européen afin de limiter notre impact écologique tout en permettant de proposer des produits neufs
                    </p>
                    <p>
                        L'avantage d'utiliser ces matériaux est qu'ils ne sont faits à base d'aucun pesticide, produit chimique ou OGM. <br/>
                        De plus, ce type d'agriculture permet de conserver la biodiversité des espaces tout en favorisant la qualité des sols. <br/>
                        Enfin, le coton biologique nécessite moins d'eau pour pousser et permet de moins polluer les cours d'eau avoisinant la culture des plants.
                    </p>

                    <h3 className="fs-3">Equité social</h3>
                    <p>
                        Nous sommes témoins des ravages que l'industrie de la fast-fashion fait au monde. <br/>
                        Always a Kid tiens à ne pas participer à l'esclavagisme moderne effectué sur les peuples minoritaires et aux enfants. <br/>
                        C'est pourquoi nous produisons nos vêtements en Europe afin de garantir un respect des droits fondamentaux humains et un salaire plus juste pour nos collaborateurs. <br/>
                        Nos produits sont fait au Portugal ou en Grèce à partir de matériaux sans composés chimiques afin qu'ils puissent avoir une meilleure sante. <br/>
                    </p>
                </div>
            <div className="glass p-3 mt-4">
                <h2 className="aak-typo fs-1">Nos produits</h2>
                <div className="row">
                {products.slice(-3).reverse().map((product,index)=>{
                return  (
                <div className="col-12 col-lg-4 p-2 product my-1" key={index}>
                    <Link to={"/product/" + product.path} onClick={() => setProductClicked(product) } className="d-block p-2">
                        <div className="card-img-top">
                            <img src={"https://database.alwaysakid.shop/storage/uploads"+product.image.path} alt={product.name} />
                        </div>
                        <div className="mt-2 product-info">
                            <h3 className="my-0 me-4 fs-4 ">{product.name}</h3>
                            <p className="my-0 mb-0 fs-5 ">{product.price}€</p>
                        </div>
                    </Link>
                </div>)              
                })}
                </div>
                </div>  
            </div>
        </div>
    );
  }
  
  export default Home;
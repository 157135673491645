import React from 'react';

function Relay ({pointsRelais, RelayShipping}) {

    return (
        pointsRelais.map((relais, index) => {
            return (
            <div key={relais.id} className="glass p-3 my-3 shippingBlock">
              <div>
                <p className="my-2">{relais.name}</p>
                <p className="my-2">{relais.street} {relais.house_number}</p>
                <p className="my-2">{relais.postal_code} {relais.city}</p>
              </div>
              <div>
                <button className="btn btn-secondary" onClick={() => RelayShipping(index)}>Choisir</button>
              </div>
            </div>)})
    );
}
export default Relay
import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";

import SearchInfo from './Shipping_Search-Info';
import Relay from './Shipping_Relay';
import Domicile from './Shipping_Domicile';
import ClientAdress from './Shipping_Client-Adress';

function Shipping({validateOrder, client_adress, setClientAdress}) {

    const [country, setCountry] = useState("FR");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [pointsRelais, setPointsRelais] = useState([]);
    const [search, setSearch] = useState("waiting");
    const [shippingMethod, setShippingMethod] = useState("waiting");

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
      };
    
    const handlePostalCodeChange = (e) => {
      setPostalCode(e.target.value);
    };

    const handleCityChange = (e) => {
      setCity(e.target.value);
    };
  
    const handleAddressChange = (e) => {
      setAddress(e.target.value);
    };

    const searchRelay = async () => {
        if(postalCode != null)
        {
          if(country === "BE"|| country === "FR"|| country === "LU"|| country === "NL"|| country === "ES")
          {
            setShippingMethod("relay");
            const url = `https://servicepoints.sendcloud.sc/api/v2/service-points?access_token=f1564e57-1d07-48d3-9f28-50f48d8f7a68&country=${country}&address=${postalCode}&radius=5000&carrier=mondial_relay`;
            const options = {
              method: 'GET',
              headers: {
                'X-Requested-With': '',
                Accept: 'application/json',
                Authorization: 'Bearer 123'
              }
            };
            
            try {
              const response = await fetch(url, options);
              const data = await response.json();
              setPointsRelais(data);
  
            } catch (error) {
              console.error(error);
            }
          }
          else{
            setShippingMethod("domicile");
          }
          setSearch("done")
        }
        else{
            alert ("Le code postal n'est pas rempli correctement")
        }
    }

    function RelayShipping (id){
      const addressToShip = {
        name:"Livraison via Mondial Relay (en point relais)",
        line: pointsRelais[id].street +" "+pointsRelais[id].house_number,
        city:pointsRelais[id].city,
        state: pointsRelais[id].state,
        postal_code: pointsRelais[id].postal_code,
        country: pointsRelais[id].country,
      }
      setClientAdress(addressToShip)
    }

    function DomicileShipping (){
      if(address && city && postalCode && country)
      {
        const addressToShip = {
          name:"Livraison via Mondial Relay (à domicile)",
          line: address,
          city:city,
          postal_code: postalCode,
          country: country,
        }
        setClientAdress(addressToShip)
      }
      else{
        alert("L'adresse que vous avez saisi n'est pas complète. \n Veuillez la véifier et la valider une nouvelle fois.");
      }
    }

    function reset ()
    {
      setSearch("waiting");
      setShippingMethod("waiting");
      setClientAdress();
    }
    

  return (
    <div id="ShippingBlock" className="container glass p-3">
        <div className="mb-3">
            <button onClick={() => validateOrder("Resume")} className="fs-6 glass px-3 py-2 me-3"> Retour </button>
        </div>
        {!client_adress && 
        <div>

          <div>
            <h2 className="text-center">Ou souhaitez-vous être livré ?</h2>
            <p className="fs-6 text-center m-0 mb-2">Livraison via Mondial Relay offerte par la maison !</p>
          </div>

          {shippingMethod !== "domicile" &&
          <div className="row">
            <div className="col-12 col-md-4 my-2">
                  <label htmlFor="country">Pays</label>
                  <select id="country" value={country} className="form-control w-100" type="text" onChange={handleCountryChange}>
                      <option value="FR">France</option>
                      <option value="BE">Belgique</option>
                      <option value="LU">Luxembourg</option>
                      <option value="NL">Pays-Bas</option>
                      <option value="DE">Allemagne</option>
                      <option value="IT">Italie</option>
                      <option value="ES">Espagne</option>
                      <option value="PT">Portugal</option>
                      <option value="UK">Royaume-Uni</option>
                      <option value="AT">Autriche</option>
                  </select>
              </div>
              <div className="col-12 col-md-4 my-2">
                  <label htmlFor="postalCode">Code postal</label>
                  <input id="postalCode" value={postalCode} className="form-control w-100" type="text" onChange={handlePostalCodeChange} />
              </div>

              <div className="col-12 col-md-4 my-2 d-flex align-items-end">
                <button className="btn btn-secondary" onClick={() => searchRelay("done") }>Rechercher</button>
              </div>
          </div>}

          {shippingMethod !== "domicile" &&
          <div className="h-40 overflow-x-hidden overflow-y-auto cart-resume glass p-3 my-3">

          {search === "waiting" &&  <SearchInfo />}

          {search === "done" && shippingMethod === "relay" && pointsRelais.length > 0 && 
            <Relay
              pointsRelais={pointsRelais}
              RelayShipping={RelayShipping}
            />
          }
          </div>}

        </div>}

        {!client_adress && search === "done" && shippingMethod === "domicile" && 
        <Domicile 
          address={address} 
          handleAddressChange={handleAddressChange} 
          postalCode={postalCode} 
          handlePostalCodeChange={handlePostalCodeChange} 
          city={city}
          handleCityChange={handleCityChange}
          country={country}
          handleCountryChange={handleCountryChange}
          DomicileShipping={DomicileShipping}
          reset={reset}
        />}

        {client_adress && 
        <ClientAdress 
          client_adress={client_adress} 
          setClientAdress={setClientAdress} 
          validateOrder={validateOrder} 
        />}
    </div>  
);
};
export default Shipping;



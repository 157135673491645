import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import {loadStripe} from '@stripe/stripe-js';

import Resume from '../Components/Cart-Components/Resume';
import Shipping from '../Components/Cart-Components/Shipping';


const apiURL = "http://localhost:3001";

function Cart({ inCart, setInCart }) {
    const [total, setTotal] = useState(0);
    const [totalWeight, setTotalWeight] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [productIDToRemove, setProductIDToRemove] = useState(null);
    const [stage, setStage] = useState("Resume");
    const [client_adress, setClientAdress] = useState();


    function changeQuantity(symbol, productID) {
        const updatedCart = inCart.map((product, index) => {
            if (index === productID) {
                let updatedQuantity;
                if (symbol === "+") {
                    updatedQuantity = product.quantity + 1;
                } else if (symbol === "-") {
                    if (product.quantity > 1) {
                        updatedQuantity = product.quantity - 1;
                    } else {
                        setProductIDToRemove(productID);
                        setShowModal(true);
                        updatedQuantity = product.quantity;
                    }
                }
                return { ...product, quantity: updatedQuantity };
            }
            return product;
        });
        setInCart(updatedCart);
    }

    function handleRemoveProductFromCart() {
        const updatedCart = inCart.filter((_, index) => index !== productIDToRemove);
        setInCart(updatedCart);
        setShowModal(false);
    }

    function calculateTotal() {
        let subTotal = 0;
        let subTotalWeight = 0;
        inCart.forEach(product => {
            subTotal += product.quantity * parseFloat(product.price);
            subTotalWeight += product.quantity * parseFloat(product.weight);
        });
        setTotal(subTotal);
        setTotalWeight(subTotalWeight);
    }

    useEffect(() => {
        calculateTotal();
    }, [inCart]);

    const makePayement = async () => {
        const stripe = await loadStripe("pk_test_51P4OERP8QtMfqwwNPPk3eyU0RWC0K74qA2hNb59AXH8hbPqfkmC81UydIdAyNcDuUk4PUT7k1QRNMK1401fcNq4o00KvHKOukZ");

        const body = {
            products: inCart,
            shipping_address: client_adress,
        }

        const headers = {
            "Content-Type":"application/json"
        }

        const response = await fetch(`${apiURL}/create-checkout-session`,{
            method:"POST",
            headers:headers,
            body:JSON.stringify(body),
        })

        const session = await response.json();
        console.log(session.id)
        const result = stripe.redirectToCheckout({
            sessionId: session.id
        })
    }

    function validateOrder(stageOrder) {
        const currentDate = new Date();
        let productsAvailable = true;
    
        const unavailableProducts = [];
    
        inCart.forEach(product => {
            const productDate = new Date(product.time);
            if (productDate > currentDate) {
            } else {
                productsAvailable = false;
                unavailableProducts.push(product.name);
            }
        });

    
        if (productsAvailable) {
            setStage(stageOrder);
            if(stageOrder === "Checkout")
            {
                console.log("Lancement du paiement");
                makePayement();
            }
        } else if (!productsAvailable) {

            const message = `Certains produits ne sont malheureusement plus disponibles :\n${unavailableProducts.join("\n")}\nCes articles ont été retirés de votre panier.`;
            alert(message);

            const updatedCart = inCart.filter(product => {
                const productDate = new Date(product.time);
                return productDate > currentDate;
            });
            setInCart(updatedCart);
        }
    }

    return (
        <main className="container">
            <div className="">
                <h1 className="my-5 text-center">Panier</h1>
            </div>

            {stage==="Resume" && <Resume inCart={inCart} validateOrder={validateOrder} changeQuantity={changeQuantity} total={total} /> }

            {stage === "Shipping" && <Shipping validateOrder={validateOrder} client_adress={client_adress} setClientAdress={setClientAdress} totalWeight={totalWeight} />}

            {stage === "Checkout" && 
                <div className="row glass p-3 d-flex align-items-center justify-content-center cart-resume mt-0 mx-1">
                    <div className="text-center">
                        <h2>Initialisation du paiement</h2>
                        <span id="spinner" className="spinner-border" aria-hidden="true"></span>
                    </div>
                </div>
            }

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Souhaitez-vous supprimer le produit de votre panier ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => setShowModal(false)}>
                        Garder le produit
                    </Button>
                    <Button variant="danger" onClick={handleRemoveProductFromCart}>
                        Supprimez le produit
                    </Button>
                </Modal.Footer>
            </Modal>
        </main>
    );
}

export default Cart;

import React, {} from 'react';

function Contact() {

    const sendInfo = () => {
        const name = document.getElementById('prenom').innerText;
        const mail = document.getElementById('mail').innerText;
        const objet = document.getElementById('objet').innerText;
        const message = document.getElementById('message').innerText;
        const empty = "";

        if(name !== empty && mail !== empty && objet !== empty && message !== empty)
        {
            const btnContact = document.getElementById("btnContact");
            const spinner = document.getElementById("spinner");
            const btnText = document.getElementById("button-text")
            btnContact.disabled = true;
            spinner.classList.remove("d-none");
            btnText.textContent = "Envoi...";
    
            setTimeout(function(){
            spinner.classList.add("d-none");
            btnText.textContent = "C'est envoyé ! ;)";
            
            },3000)  
        }
        else{
            alert("Complétez le formulaire avant de l'envoyer afin que nous puissions vous aidez au mieux")
        }
 
      }

    return (
        <main className="container">
            <div className="py-5">
                <h1 className="my-5 text-center">Contact</h1>
                <form className="glass p-3">

                    <div className="my-3">
                        <label className="fs-5" for="prenom">Prénom</label>
                        <input id="prenom" className="form-control-lg d-block w-100 glass" type="text" placeholder="Aymeric" required />
                    </div>

                    <div className="my-3">
                        <label className="fs-5" for="mail">Adresse e-mail</label>
                        <input id="mail" className="form-control-lg d-block w-100 glass" type="mail" placeholder="info@alwaysakid.shop" required />
                    </div>

                    <div className="my-3">
                        <label className="fs-5" for="objet">Objet</label>
                        <input className="form-control-lg d-block w-100 glass" list="datalistOptions" id="objet" placeholder="Objet" required />
                        <datalist id="datalistOptions">
                            <option value="J'ai un problème pour passer commande" />
                            <option value="J'ai un soucis avec ma commande" />
                            <option value="J'aimerais avoir des informations sur vos produits" />
                            <option value="Je voudrais organiser un partenariat" />
                        </datalist>                    
                    </div>

                    <div className="my-3">
                        <label className="fs-5" for="message">Votre message</label>
                        <textarea id="message" className="form-control-lg d-block w-100 glass" rows="7" placeholder="Comment pouvons-nous vous aider ?" required />
                    </div>

                    <button onClick={() => sendInfo()} id="btnContact" type="button" className="d-flex justify-content-center align-items-center btn btn-primary mt-4 py-2 w-75 mx-auto">
                        <span id="spinner" className="spinner-border d-none" aria-hidden="true"></span>
                        <span id="button-text" role="status" className="fs-5 ms-2 py-1">Envoyer</span>
                    </button>          
                </form>
            </div>
        </main>
    );
  }
  
  export default Contact;
import React from 'react';

function Domicile ({address, handleAddressChange, postalCode, handlePostalCodeChange, city, handleCityChange, country, handleCountryChange, DomicileShipping, reset}) {


    return (
        <div className="glass p-3 my-3 row">

            <div className="col-12 col-md-6 my-2">
                <label htmlFor="address">Rue + Numéro</label>
                <input id="address" value={address} className="form-control w-100" type="text" onChange={handleAddressChange} />
            </div>

            <div className="col-12 col-md-6 my-2">
                <label htmlFor="postalCode">Code postal</label>
                <input id="postalCode" value={postalCode} className="form-control w-100" type="text" onChange={handlePostalCodeChange} />
            </div>

            <div className="col-12 col-md-6 my-2">
                <label htmlFor="city">Ville</label>
                <input id="city" value={city} className="form-control w-100" type="text" onChange={handleCityChange} />
            </div>

            <div className="col-12 col-md-6 my-2">
                <label htmlFor="country">Pays</label>
                <input id="country" value={country} className="form-control w-100" type="text" onChange={handleCountryChange} disabled />
            </div>

            <div className="d-flex align-items-center justify-content-between">
                <button className="btn btn-link p-0 text-secondary fs-6 m-0" onClick={() => reset()}>Modifier</button>
                <button className="btn btn-secondary mt-3" onClick={() => DomicileShipping()}>Valider mon adresse</button>
            </div>
            
        </div>
    );
}
export default Domicile
import React from 'react';

function ClientAdress ({client_adress, setClientAdress, validateOrder}) {


    return (
        <div>
        <div className="my-3 glass p-3">
          <h3>Vous serez livré ici :</h3>
          <div>
            <p className="my-2">{client_adress.line}</p>
            <p className="my-2">{client_adress.postal_code} {client_adress.city}, {client_adress.country}</p>
            <button className="btn btn-link p-0 text-secondary fs-6 m-0" onClick={() => setClientAdress()}>Modifier</button>
          </div>
        </div>

        <div className="w-100">
            <button className="btn btn-primary w-100 btn-commande mx-2 fs-4 mt-3" onClick={() => validateOrder("Checkout")}>Valider la commande</button>
        </div>
        
      </div>
    );
}
export default ClientAdress
import React from 'react';

function SearchInfo () {
    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center fs-6 darkblue">
                <p>Choisissez votre pays de résidence ainsi que le code postal correspondant à votre commune</p>
                <p>Cela effectuera une recherche des points relais "Mondial Relay" se trouvant dans un rayon de 20km</p>
                <p>Si votre pays n'accepte pas les livraisons en point relais par "Mondial Relay", votre adresse vous sera demandé</p>
            </div>
        </div>
    );
}
export default SearchInfo
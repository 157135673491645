import React, {} from 'react';
import {Link} from "react-router-dom";

function Cancel() {
    return (
        <main className="container">
            <div className="py-5">
                <h1 className="my-5 text-center">Echec du paiement</h1>
                <div className="glass p-3">
                    <h2>Il y a eu un soucis avec votre commande</h2>
                    <p>Nous n'avons pas reçu votre commande dû à l'annulation de la procédure de paiement.</p>
                    <p>Si cela était intentionnel, ne vous inquietez pas, il n'y a aucun problème.</p>
                    <p>Autrement, veuillez réessayer.</p>
                    <p>Vous n'avez pas été débité lors de cette erreur.</p>
                    <p>Si le problème persiste merci de nous contacter via <Link to="/contact"  className="text-decoration-underline link-offset-1 orange">la page contact</Link> ou de contacter votre banque.</p>
                    <p>Si vous avez une question, merci de nous la faire parvenir par mail à <Link to="mailto:info@alwaysakid.shop" className="text-decoration-underline link-offset-1 orange">info@alwaysakid.shop</Link>.</p>
                    <p>Nous esperons vous revoir chez Always a Kid.</p>
                    <p>À bientôt !</p>
                    <Link to="/shop" className="btn btn-primary">Revenir au shop</Link>
                </div>
            </div>
        </main>
    );
  }
  
  export default Cancel;
import {Link} from "react-router-dom"

function Shop({products,setProductClicked}) {
    return (
        <main className="container">
            <div className="py-5">
                <h1 className="my-5 text-center">Shop</h1>
                <div>
                    <div className="row d-flex justify-content-start">
                        {products.length > 0 && products.map((product,index)=>{
                        return (
                        <div className="col-12 col-lg-4 p-2 product my-1" key={index}>
                            <Link to={"/product/" + product.path} onClick={() => setProductClicked(product) } className="d-block glass p-3">
                                <div className="card-img-top">
                                    <img src={"https://database.alwaysakid.shop/storage/uploads"+product.image.path} alt={product.name} />
                                </div>
                                <div className="mt-2 product-info">
                                    <h3 className="my-0 me-4 fs-4 ">{product.name}</h3>
                                    <p className="my-0 mb-0 fs-5 ">{product.price}€</p>
                                </div>
                            </Link>
                        </div>)                  
                        })}
                        {products.length === 0 && 
                        <div className="p-2">
                            <div className="text-center glass fs-2 darkblue p-2">
                                <p>Il n'y a aucun article en vente actuellement.</p>
                                <p>Nous vous invitons à revenir plus tard lorsqu'un drop sera annoncé sur nos réseaux sociaux.</p>
                            </div>
                        </div>}

                    </div>
                </div>
            </div>
        </main>
    );
  }
  
  export default Shop;
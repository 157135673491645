import React, {useState } from 'react';
import { Link } from 'react-router-dom';

function Navigation({inCart}) {

  const [NavState, setNavState] = useState("close");
  const totalProductsInCart = calculateTotalProducts(inCart);

  function calculateTotalProducts(cart) {
    return cart.reduce((total, product) => total + product.quantity, 0);
  }

  function changeNavState() {
    const element = document.getElementById("menu-button");
  
    if (NavState === "close") {
      setNavState("open");
      element.innerText = "";
      setTimeout(function () {
        element.innerText = "X";
      }, 500);
    } else {
      setNavState("close");
      element.innerText = "";
      setTimeout(function () {
        element.innerText = "Menu";
      }, 500);
    }
  }
  
  return (
    <nav className="fixed-top z-5">
      <div className="NoPC" >
        <img className="z-1 position-absolute end-0 w-50" src="./img/sun.webp" alt="soleil" />
        <span id="menu-button" onClick={changeNavState} className={"position-absolute end-0 m-4 me-2 menu-button menu-button-"+NavState}>Menu</span>
      </div>
      
      <div id="menu-content" className={"d-flex align-items-center menu-content-"+NavState} >
        <div class="w-100 mx-4">
          <Link to="/" className="d-flex align-items-center logo-box" onClick={changeNavState}>
              <span class="logo" aria-label="logo Always a Kid" >Always<br/> a Kid</span>
          </Link>
          <div class="cloud-box">
            <div id="cloud-nav-1" className="text-center position-relative clouds px-2">
              <img className="w-100 z-1 mt-3" src="./img/cloud-2.webp" alt="nuage de navigation 1" />
              <Link to="/shop" className="d-block menu-link z-2 mt-2" onClick={changeNavState}>Shop</Link> 
            </div>
            <div id="cloud-nav-2" className="text-center position-relative clouds px-2">
              <img className="w-100 z-1 mt-3" src="./img/cloud-3.webp" alt="nuage de navigation 2" />
              <Link to="/faq" className="d-block menu-link z-2 mt-2" onClick={changeNavState}>FAQ</Link> 
            </div>
            <div id="cloud-nav-3" className="text-center position-relative clouds px-2">
              <img className="w-100 z-1 mt-2" src="./img/cloud-1.webp" alt="nuage de navigation 3" />
              <Link to="/cart" className="d-block menu-link z-2" onClick={changeNavState}>
                Panier
                <span class="badge badge-cart bg-danger fs-6 py-2 rounded-circle">{totalProductsInCart}</span>
              </Link> 
            </div>
          </div>

        </div>
      </div>

    </nav>
  );
}

export default Navigation;

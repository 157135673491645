import React, { useEffect } from 'react';
import {Link} from "react-router-dom";

function Resume({inCart, validateOrder, changeQuantity, total }) {

  return (
<div className="glass p-3">
<div className="glass overflow-y-auto cart-resume p-2">

    {inCart.length > 0 && inCart.map((product, index) => {
        return <div key={index} className="d-flex align-items-center h-40 py-3 overflow-x-hidden" >
                    <img className="d-inline-block mw-50 h-100 me-2 align-self-start cart-img" src={"https://database.alwaysakid.shop/storage/uploads"+product.image.path} alt="produitName" />

                    <div className="w-100 h-100 cart-product px-3">
                        <div>
                            <h2 className="d-block m-0 cart-product-title">{product.name}</h2>
                            <span>Taille : {product.size}</span>
                        </div>
                        <div id="btn-group-quantity" className="btn-group btn-group-quantity border-dark border px-3 py-1 my-2" role="group" aria-label="Changer la quantité">
                                <button type="button" className="btn pe-0" onClick={() => changeQuantity("-", index)}>-</button>
                                <button type="button" className="btn fs-5 btn-quantity" >{product.quantity}</button>
                                <button type="button" className="btn ps-0" onClick={() => changeQuantity("+", index)}>+</button>
                        </div>
                        <p className="d-block my-0 cart-product-price fs-4">{product.price}€</p>
                    </div>

                </div>
            })
    }

    {inCart.length === 0 &&
        <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center fs-2 darkblue">
                <p>Vous n'avez aucun article dans votre panier.</p>
                <p>Remplissez-le en passant par <Link to="/shop" className="text-decoration-underline link-offset-1 aak-typo orange">le shop</Link></p>
            </div>
        </div>}
</div>
{inCart.length > 0 &&
    <div className="mt-3">
        <p className="fs-4 text-end"><span className="glass px-3 py-2"> Total: <span className="d-inline-block total-price-cart">{total}</span>€ </span></p>
        <button onClick={() =>validateOrder("Shipping")} className="w-100 btn btn-primary fs-4 btn-commande">Continuer</button>
    </div>
}
{inCart.length === 0 && <Link to="/shop" className="w-100 mt-3 btn btn-primary fs-4 btn-commande">Allez au Shop</Link>}
</div>
  );
};
export default Resume;



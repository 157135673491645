import Accordion from '../Components/Accordion';
import React, { useEffect, useState } from 'react';

function Product({product, setInCart, inCart}) {

    const [sizeChoice, setSizeChoice] = useState("S");
    const [quantityChoice, setQuantityChoice] = useState(1);
    const [btnCommande, setBtnCommande] = useState("Ajouter au panier");

    useEffect(() => {
        const sizeGroup = document.querySelectorAll(".btn-size");
        sizeGroup.forEach(size => {
            if(size.classList.contains("active"))
            {size.classList.remove("active");}
            if(size.innerText === sizeChoice)
            {size.classList.add("active")}
        });
      }, [sizeChoice]);

      useEffect(() => {
        const currentDate = new Date();
        const productDate = new Date(product.time);

        if (currentDate > productDate)
        {
            const btnAddCart = document.getElementById("btn-add-cart");
            btnAddCart.disabled = true;
            btnAddCart.classList.add("bg-danger");
            setBtnCommande("Sold Out");
        }
      }, [product.time]);

      function addToCart() {
        const currentDate = new Date();
        const productDate = new Date(product.time);
        if (currentDate < productDate)
        {      
            const productToAdd = {
                image: product.image,
                name: product.name,
                price: parseFloat(product.price),
                size: sizeChoice,
                quantity: quantityChoice,
                time:product.time,
                weight:product.weight,
            };
            // Vérifie si un produit avec la même taille et le même nom est déjà dans le panier
            const existingProductIndex = inCart.findIndex(item => item.name === productToAdd.name && item.size === productToAdd.size);    
            if (existingProductIndex !== -1) {
                // Si un produit avec la même taille existe déjà, mettez à jour la quantité
                const updatedCart = [...inCart];
                updatedCart[existingProductIndex].quantity += quantityChoice;
                setInCart(updatedCart);
            } else {
                // Sinon, ajoutez le nouveau produit au panier
                const updatedCart = [...inCart, productToAdd];
                setInCart(updatedCart);
            }
            const btnAddCart = document.getElementById("btn-add-cart");
            btnAddCart.disabled = true;
            setBtnCommande("L'article a été ajouté !");
            setTimeout(() => {
                btnAddCart.disabled = false;
                setBtnCommande("Ajoutez au panier");
            }, 3000);
        }

   }

    return (
        <main className="container mt-5">
            <div key={product.path} className="row w-100 mx-auto glass p-3 my-3">
                <div className="col-md-6 my-2">
                    <div id="product-image" className="card-img-top">
                        <img src={"https://database.alwaysakid.shop/storage/uploads"+product.image.path} alt={product.name} />
                    </div>
                </div>
                <div className="col-md-6 my-2">
                    <h1 id="product-name" className="product-name mb-3">{product.name}</h1>
                    <p  id="product-price" className="product-price my-3">{product.price}€</p>
                    <div id="btn-group-size" className="my-3 btn-group-size">
                        {product.size.map((size,index)=>{
                            return <button key={"size-"+index} type="button" onClick={() => setSizeChoice(size)} className="btn btn-size fs-5 rounded mx-1 border border-dark text-center">{size}</button>
                        })}
                    </div>
                    <div id="btn-group-quantity" className="btn-group btn-group-quantity btn-group-quantity-product d-flex border-dark border px-2 py-1" role="group" aria-label="Basic example">
                        <button type="button" className="btn" onClick={() => {if(quantityChoice > 1){setQuantityChoice(quantityChoice-1)}} }>-</button>
                        <button type="button" className="btn fs-5 btn-quantity" >{quantityChoice}</button>
                        <button type="button" className="btn" onClick={() => setQuantityChoice(quantityChoice+1)}>+</button>
                    </div>
                    <button onClick={() => addToCart()} id="btn-add-cart" className="btn btn-primary btn-add-cart w-100 fs-4 px-4 my-2 mx-auto"> {btnCommande} </button>
                    {product.description.map((texte,index)=>{
                            return <p className="my-2" id={"product-description"+index} key={"product-description"+index}>{texte}</p>
                        })}
                    <div id="badges" className="w-100">
                        <img width="45" className="mx-2" src="./img/icons/responsible.webp" alt="badge d'écologie" />
                        <img width="45" className="mx-2" src="./img/icons/ethic.webp" alt="badge d'éthique" />
                        <img width="45" className="mx-2" src="./img/icons/secure.webp" alt="badge de sécurité" />
                    </div>
                    {product.questions.length > 0 && <Accordion questions={product.questions} />}
                </div>
            </div>
        </main>
    );
  }
  
  export default Product;
import React, {} from 'react';
import Accordion from '../Components/Accordion';

const questions = [
    {
        name:"Dans combien de temps arrive ma commande ?",
        reponse:"Afin de ne pas participer à la surproduction de vêtements, les produits sont fabriqués à l'unité. Après le passage de votre commande, vous recevrez votre colis sous 3 à 4 semaines. Vous serez informés de l'avancer de votre commande par mail.",
    },
    {
        name:"Où sont fait les produits de Always a Kid ?",
        reponse:"Les produits de Always a Kid sont fait en Europe. Principalement au Portugal et en Grèce afin d'assurer une qualité exemplaire, un bon salaire aux travailleurs et de réduire l'empreinte carbone dû aux transports",
    },
    {
        name:"En quoi sont fait les articles de Always a Kid ?",
        reponse:"Les articles de Always a Kid sont fait exclusivement de matériaux durables et écologiques. Les matières sont donc biologique et/ou recyclés afin de diminuer l'impact écologique causé par la production de vêtement",
    },
    {
        name:"Pourquoi faites-vous des pré-commandes ?",
        reponse:"Always a Kid mets en avant une pensée écologique dans son processus de création. L'industrie de la mode est la seconde industrie la plus polluante et nous ne voulons pas ajouter notre pierre à cette édifice. Nous utilisons alors les pré-commandes afin de garantir que chaque pièce produite est au préalable vendu et ne sera donc jamais un déchet de plus pour la planète",
    },
    {
        name:"J'ai un problème avec ma commande, que faire ?",
        reponse:"Si tu as un soucis avec ta commande, n'hésite pas à nous contacter par mail à info@alwaysakid.shop ou par notre page de contact situé dans le bas de la page. N'oublie pas de préciser ton problème et d'ajouter ton numéro de commande dans ton message",
    },
]

function FAQ() {
    return (
        <main className="container">
            <div className="py-5">
                <h1 className="my-5 pb-4 text-center">FAQ</h1>
                <div className="p-3 glass">
                    <Accordion questions={questions} />
                </div>
            </div>
        </main>
    );
  }
  
  export default FAQ;
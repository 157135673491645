import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer class="mb-3 mt-5">
      <img alt="herbe" class="w-100 fixed-bottom grass m-0" src="./img/grass.webp"  />
      <div class="z-1 position-relative container px-3">
          <div className="row glass p-3">
            <div className="col-md-4 my-1 order-1 text-md-start text-center">
                <Link to="/" class="d-block footer-link my-2">Accueil</Link>
                <Link to="/shop" class="d-block footer-link my-2">Shop</Link>
                <Link to="/faq" class="d-block footer-link my-2">FAQ</Link>
                <Link to="/contact" class="d-block footer-link my-2">Contact</Link>
                <Link to="/mentions-légales" class="d-block footer-link my-2">Mentions légales</Link>
                <p class="">© Always a Kid - 2024</p>
            </div>
            <div className="col-md-4 my-1 order-last order-md-2">
              <div class="paiement-methods text-center d-flex flex-column justify-content-end h-100">
                  <p class="m-0">Paiements sécurisés par Stripe </p>
                  <div>
                    <img src="./img/payment-methods/visa.webp" alt="visa" />
                    <img src="./img/payment-methods/mastercard.webp" alt="mastercard" />
                    <img src="./img/payment-methods/bancontact.webp" alt="bancontact" />
                    <img src="./img/payment-methods/apple-pay.webp" alt="apple-pay" />
                    <img src="./img/payment-methods/google-pay.webp" alt="google-pay" />
                  </div>
                </div>
            </div>
            <div className="col-md-4 my-1 order-3">
              <div id="socialFooter" class="d-flex justify-content-center">
                <Link class="m-1" to="https://www.instagram.com/alwaysakid.shop/" target='_blank'>
                  <img width="40" src="./img/social/instagram.webp" alt="instagram" />
                </Link>
                <Link class="m-1" to="https://www.tiktok.com/@alwaysakid.shop" target='_blank'>
                  <img width="40" src="./img/social/tiktok.webp" alt="tiktok" />
                </Link>
                <Link class="m-1" to="https://x.com/alwaysakidshop" target='_blank'>
                  <img width="40" src="./img/social/x.webp" alt="x" />
                </Link>
                <Link class="m-1" to="https://www.linkedin.com/company/alwaysakid/" target='_blank'>
                  <img width="40" src="./img/social/linkedin.webp" alt="linkedin" />
                </Link>
              </div>
            </div>

          </div>
          
      </div>
    </footer>
  );
}

export default Footer;

import React, {} from 'react';
import {Link} from 'react-router-dom'

function Success() {
    return (
        <main className="container">
            <div className="py-5">
                <h1 className="my-5 text-center">Merci</h1>
                <div className="glass p-3">
                    <h2>Nous avons bien reçu votre commande</h2>
                    <p>Vous recevrez un récapitulatif de votre commande par mail, ainsi que toutes avancées de celle-ci.</p>
                    <p>Si vous avez une question, merci de nous la faire parvenir par mail à <a href="mailto:info@alwaysakid.shop" className="text-decoration-underline link-offset-1 orange">info@alwaysakid.shop</a></p>
                    <p>Nous esperons que vous serez satisfait.e de votre commande chez Always a Kid.</p>
                    <p>À bientôt !</p>
                    <Link to="/shop" className="btn btn-primary">Revenir au shop</Link>
                </div>
            </div>
        </main>
    );
  }
  
  export default Success;
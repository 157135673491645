import React, {} from 'react';


function Accordion({questions}) {
    function accordion (id){
        const accordionItems = document.querySelectorAll('.accordion-item');

        accordionItems.forEach(function(item) {
          //const header = item.querySelector('.accordion-title');
          const content = item.querySelector('.accordion-content-body');
      
            if (content.classList.contains('active')) {
              content.classList.remove('active');
            } 
          });

        const clicked = document.getElementById("accordion-content-"+id)
        clicked.classList.add('active');

        }

    return (
    <div class="accordion">
        {questions.length > 0 && questions.map((question,index)=>{
        return  <div id={"accordion-item-"+index} class="accordion-item glass my-3">
                    <div id={"accordion-title-"+index} onClick={() => accordion(index)} class="accordion-title d-flex justify-content-between align-items-center py-2 px-3">
                        <h3 class="m-0 fs-4">{question.name}</h3>
                        <div class="accordion-icon">+</div>
                    </div>

                    <div id={"accordion-content-"+index} class="accordion-content-body">
                        <div class="px-3 py-2">
                            {question.reponse}
                        </div>
                    </div>
                </div>
        })}
    </div>
    );
  }
  
  export default Accordion;
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import Navigation from './Components/Navigation';
import Footer from './Components/Footer';

import Home from './Pages/Home';
import Shop from './Pages/Shop';
import FAQ from './Pages/FAQ';
import Cart from './Pages/Cart';
import Product from './Pages/Product';
import Success from './Pages/Success';
import Cancel from './Pages/Cancel';
import Contact from './Pages/Contact';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch('https://database.alwaysakid.shop/api/content/items/products')
      .then(response => response.json())
      .then(data => {
        // Stocker les données dans l'état 'products'
        setProducts(data);
      })
      .catch(error => console.error('Erreur lors de la récupération des données :', error));
  }, []);

  const [productClicked, setProductClicked] = useState(() => {
    const storedProductClicked = localStorage.getItem('productClicked');
    return storedProductClicked ? JSON.parse(storedProductClicked) : [];
  });

  useEffect(() => {
    localStorage.setItem('productClicked', JSON.stringify(productClicked));
  }, [productClicked]);

  const [inCart, setInCart] = useState(() => {
    const storedInCart = localStorage.getItem('inCart');
    return storedInCart ? JSON.parse(storedInCart) : [];
  });

  useEffect(() => {
    localStorage.setItem('inCart', JSON.stringify(inCart));
  }, [inCart]);



  return (
    <Router>
      <ScrollToTop />
      <header>
        <Navigation inCart={inCart} setInCart={setInCart} />
      </header>

      <Routes>
        <Route path="/" exact element={<Home products={products} setProductClicked={setProductClicked} />} />
        <Route path="/shop" element={<Shop products={products} setProductClicked={setProductClicked} />} />
        <Route path="/product/:productPath" element={<Product product={productClicked} setInCart={setInCart} inCart={inCart} />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/cart" element={<Cart inCart={inCart} setInCart={setInCart} />} />
        <Route path="/payment-success" element={<Success />} />
        <Route path="/payment-cancel" element={<Cancel />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
